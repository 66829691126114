<template>
    <div>
        <div class="rn-service-details ptb--120 bg_color--5">
            <v-container v-if="$store.state.servicios.length > 0">
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35">
                    <v-col lg="6" md="6" cols="12" order="2" order-md="1">
                        <div class="details mt_md--30 mt_sm--30">
                            <div class="section-title">
                                <span class="subtitle">{{
                                    $store.state.servicios[0].SUBTITULO
                                }}</span>
                                <h2 class="heading-title">
                                    {{ $store.state.servicios[0].TITULO }}
                                </h2>
                                <p class="description">
                                    {{ $store.state.servicios[0].INFO_SRV }}
                                </p>
                            </div>
                            <globaleditor
                                store="update_Servicio"
                                state="servicios"
                                index="0"
                                variable="HTML"
                            >
                                <div class="mt-5">
                                    <v-text-field
                                        v-model="
                                            $store.state.servicios[0].TITULO
                                        "
                                        label="Titulo"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="
                                            $store.state.servicios[0].SUBTITULO
                                        "
                                        label="Sub Titulo"
                                        outlined
                                    ></v-text-field>
                                </div>
                            </globaleditor>
                            <formatter
                                :HTMLdata="$store.state.servicios[0].HTML"
                            />

                            <!-- <ul class="list-style--1 mt--30">
                <li v-for="list in listOne" :key="list.id">
                  <i v-html="iconSvg(list.icon)"></i>
                  {{ list.desc }}
                </li>
              </ul> -->
                        </div>
                    </v-col>
                    <v-col lg="6" md="6" cols="12" order="1" order-md="2">
                        <div class="thumb position-relative">
                            <img
                                class="w-100"
                                :src="$store.state.servicios[0].ICON"
                                alt="Service Images"
                            />
                        </div>
                    </v-col>
                </v-row>
                <!-- End Single Content  -->
            </v-container>
        </div>
        <div
            v-if="$store.state.servicios.length > 0"
            class="rn-service-details ptb--120 bg_color--1"
        >
            <v-container>
                <v-row>
                    <v-col lg="12">
                        <div class="service-details-inner">
                            <div class="inner">
                                <!-- Start Single Content  -->
                                <v-row
                                    class="sercice-details-content align-center row--35"
                                >
                                    <v-col lg="6" md="6" cols="12">
                                        <div class="thumb position-relative">
                                            <CoolLightBox
                                                :items="items"
                                                :index="index"
                                                @close="index = null"
                                            >
                                            </CoolLightBox>

                                            <div class="position-relative">
                                                <div
                                                    v-for="(image,
                                                    imageIndex) in items"
                                                    :key="imageIndex"
                                                >
                                                    <img
                                                        class="w-100"
                                                        :src="
                                                            $store.state
                                                                .servicios[1]
                                                                .ICON
                                                        "
                                                        alt="About Images"
                                                    />
                                                    <!-- <a @click="index = imageIndex" class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col lg="6" md="6" cols="12">
                                        <div
                                            class="details mt_md--30 mt_sm--30"
                                        >
                                            <div class="section-title">
                                                <span class="subtitle"
                                                    > {{
                                                        $store.state
                                                            .servicios[1].SUBTITULO
                                                    }}</span
                                                >
                                                <h2 class="heading-title">
                                                    {{
                                                        $store.state
                                                            .servicios[1].TITULO
                                                    }}
                                                </h2>
                                                <!-- <p class="description">
                                                    {{
                                                        $store.state
                                                            .servicios[1]
                                                            .INFO_SRV
                                                    }}
                                                </p> -->
                                            </div>
                                            <globaleditor
                                                store="update_Servicio"
                                                state="servicios"
                                                index="1"
                                                variable="HTML"
                                            >
                                                <div class="mt-5">
                                                    <v-text-field
                                                        v-model="
                                                            $store.state
                                                                .servicios[1]
                                                                .TITULO
                                                        "
                                                        label="Titulo"
                                                        outlined
                                                    ></v-text-field>
                                                    <v-text-field
                                                        v-model="
                                                            $store.state
                                                                .servicios[1]
                                                                .SUBTITULO
                                                        "
                                                        label="Sub Titulo"
                                                        outlined
                                                    ></v-text-field>
                                                </div>
                                            </globaleditor>
                                            <formatter
                                                :HTMLdata="
                                                    $store.state.servicios[1]
                                                        .HTML
                                                "
                                            />
                                            <!-- <ul class="liststyle mt--30">
                        <li>Yet this above sewed flirted opened ouch</li>
                        <li>Goldfinch realistic sporadic ingenuous</li>
                        <li>Abominable this abidin far successfully then like piquan</li>
                        <li>Risus commodo viverra</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                      </ul> -->
                                        </div>
                                    </v-col>
                                </v-row>
                                <!-- End Single Content  -->
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <div
            v-if="$store.state.servicios.length > 0"
            class="rn-service-details ptb--120 bg_color--5"
        >
            <v-container>
                <!-- Start Single Content  -->
                <v-row class="sercice-details-content align-center row--35">
                    <v-col lg="6" md="6" cols="12" order="2" order-md="1">
                        <div class="details mt_md--30 mt_sm--30">
                            <div class="section-title">
                                <span class="subtitle">{{
                                    $store.state.servicios[2].SUBTITULO
                                }}</span>
                                <h2 class="heading-title">
                                    {{ $store.state.servicios[2].TITULO }}
                                </h2>
                                <!-- <p class="description">
                                    {{ $store.state.servicios[2].INFO_SRV }}
                                </p> -->
                            </div>
                            <globaleditor
                                store="update_Servicio"
                                state="servicios"
                                index="2"
                                variable="HTML"
                            >
                                <div class="mt-5">
                                    <v-text-field
                                        v-model="
                                            $store.state.servicios[2].TITULO
                                        "
                                        label="Titulo"
                                        outlined
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="
                                            $store.state.servicios[2].SUBTITULO
                                        "
                                        label="Sub Titulo"
                                        outlined
                                    ></v-text-field>
                                </div>
                            </globaleditor>
                            <formatter
                                :HTMLdata="$store.state.servicios[2].HTML"
                            />
                            <!-- <ul class="list-style--1 mt--30">
                <li v-for="list in listOne" :key="list.id">
                  <i v-html="iconSvg(list.icon)"></i>
                  {{ list.desc }}
                </li>
              </ul> -->
                        </div>
                    </v-col>
                    <v-col lg="6" md="6" cols="12" order="1" order-md="2">
                        <div class="thumb position-relative">
                            <img
                                class="w-100"
                                :src="$store.state.servicios[2].ICON"
                                alt="Service Images"
                            />
                        </div>
                    </v-col>
                </v-row>
                <!-- End Single Content  -->
            </v-container>
        </div>
        <div
            v-if="$store.state.servicios.length > 0"
            class="rn-service-details ptb--120 bg_color--1"
        >
            <v-container>
                <v-row>
                    <v-col lg="12">
                        <div class="service-details-inner">
                            <div class="inner">
                                <!-- Start Single Content  -->
                                <v-row
                                    class="sercice-details-content align-center row--35"
                                >
                                    <v-col lg="6" md="6" cols="12">
                                        <div class="thumb position-relative">
                                            <CoolLightBox
                                                :items="items"
                                                :index="index"
                                                @close="index = null"
                                            >
                                            </CoolLightBox>

                                            <div class="position-relative">
                                                <div
                                                    v-for="(image,
                                                    imageIndex) in items"
                                                    :key="imageIndex"
                                                >
                                                    <img
                                                        class="w-100"
                                                        :src="
                                                            $store.state
                                                                .servicios[3]
                                                                .ICON
                                                        "
                                                        alt="About Images"
                                                    />
                                                    <!-- <a @click="index = imageIndex" class="video-popup play__btn"
                            ><span class="play-icon"></span
                          ></a> -->
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col lg="6" md="6" cols="12">
                                        <div
                                            class="details mt_md--30 mt_sm--30"
                                        >
                                            <div class="section-title">
                                                <span class="subtitle"
                                                    > {{
                                                        $store.state
                                                            .servicios[3].SUBTITULO
                                                    }}</span
                                                >
                                                <h2 class="heading-title">
                                                    {{
                                                        $store.state
                                                            .servicios[3].TITULO
                                                    }}
                                                </h2>
                                                <!-- <p class="description">
                                                    {{
                                                        $store.state
                                                            .servicios[3]
                                                            .INFO_SRV
                                                    }}
                                                </p> -->
                                            </div>
                                            <globaleditor
                                                store="update_Servicio"
                                                state="servicios"
                                                index="3"
                                                variable="HTML"
                                            >
                                                <div class="mt-5">
                                                    <v-text-field
                                                        v-model="
                                                            $store.state
                                                                .servicios[3]
                                                                .TITULO
                                                        "
                                                        label="Titulo"
                                                        outlined
                                                    ></v-text-field>
                                                    <v-text-field
                                                        v-model="
                                                            $store.state
                                                                .servicios[3]
                                                                .SUBTITULO
                                                        "
                                                        label="Sub Titulo"
                                                        outlined
                                                    ></v-text-field>
                                                </div>
                                            </globaleditor>
                                            <formatter
                                                :HTMLdata="
                                                    $store.state.servicios[3]
                                                        .HTML
                                                "
                                            />
                                            <!-- <ul class="liststyle mt--30">
                        <li>Yet this above sewed flirted opened ouch</li>
                        <li>Goldfinch realistic sporadic ingenuous</li>
                        <li>Abominable this abidin far successfully then like piquan</li>
                        <li>Risus commodo viverra</li>
                        <li>Lorem ipsum dolor sit amet, consectetur adipiscing</li>
                      </ul> -->
                                        </div>
                                    </v-col>
                                </v-row>
                                <!-- End Single Content  -->
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </div>
</template>

<script>
import feather from "feather-icons";
import formatter from "../htmlformatter/index.vue";
import globaleditor from "../globaleditor/index.vue";
export default {
    components: {
        formatter,
        globaleditor,
    },
    data() {
        return {
            index: null,
            items: [
                {
                    thumb: require("../../assets/images/service/service-01.png"),
                    src: "https://www.youtube.com/watch?v=ZOoVOfieAF8",
                },
            ],
            listOne: [
                {
                    id: 1,
                    icon: "check",
                    desc: `5 PPC Campaigns the majority.`,
                },
                {
                    id: 2,
                    icon: "check",
                    desc: `Digital Marketing going to.`,
                },
                {
                    id: 3,
                    icon: "check",
                    desc: ` Marketing Agency passage of.`,
                },
                {
                    id: 4,
                    icon: "check",
                    desc: ` Seo Friendly you are going.`,
                },
                {
                    id: 5,
                    icon: "check",
                    desc: `5 PPC Campaigns the majority.`,
                },
                {
                    id: 6,
                    icon: "check",
                    desc: `Fast-Track Your business`,
                },
            ],
        };
    },
    methods: {
        iconSvg(icon) {
            return feather.icons[icon].toSvg();
        },
    },
};
</script>

<style></style>
