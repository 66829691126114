var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"rn-service-details ptb--120 bg_color--5"},[(_vm.$store.state.servicios.length > 0)?_c('v-container',[_c('v-row',{staticClass:"sercice-details-content align-center row--35"},[_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12","order":"2","order-md":"1"}},[_c('div',{staticClass:"details mt_md--30 mt_sm--30"},[_c('div',{staticClass:"section-title"},[_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$store.state.servicios[0].SUBTITULO))]),_c('h2',{staticClass:"heading-title"},[_vm._v(" "+_vm._s(_vm.$store.state.servicios[0].TITULO)+" ")]),_c('p',{staticClass:"description"},[_vm._v(" "+_vm._s(_vm.$store.state.servicios[0].INFO_SRV)+" ")])]),_c('globaleditor',{attrs:{"store":"update_Servicio","state":"servicios","index":"0","variable":"HTML"}},[_c('div',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"Titulo","outlined":""},model:{value:(
                                        _vm.$store.state.servicios[0].TITULO
                                    ),callback:function ($$v) {_vm.$set(_vm.$store.state.servicios[0], "TITULO", $$v)},expression:"\n                                        $store.state.servicios[0].TITULO\n                                    "}}),_c('v-text-field',{attrs:{"label":"Sub Titulo","outlined":""},model:{value:(
                                        _vm.$store.state.servicios[0].SUBTITULO
                                    ),callback:function ($$v) {_vm.$set(_vm.$store.state.servicios[0], "SUBTITULO", $$v)},expression:"\n                                        $store.state.servicios[0].SUBTITULO\n                                    "}})],1)]),_c('formatter',{attrs:{"HTMLdata":_vm.$store.state.servicios[0].HTML}})],1)]),_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12","order":"1","order-md":"2"}},[_c('div',{staticClass:"thumb position-relative"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.$store.state.servicios[0].ICON,"alt":"Service Images"}})])])],1)],1):_vm._e()],1),(_vm.$store.state.servicios.length > 0)?_c('div',{staticClass:"rn-service-details ptb--120 bg_color--1"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"service-details-inner"},[_c('div',{staticClass:"inner"},[_c('v-row',{staticClass:"sercice-details-content align-center row--35"},[_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12"}},[_c('div',{staticClass:"thumb position-relative"},[_c('CoolLightBox',{attrs:{"items":_vm.items,"index":_vm.index},on:{"close":function($event){_vm.index = null}}}),_c('div',{staticClass:"position-relative"},_vm._l((_vm.items),function(image,imageIndex){return _c('div',{key:imageIndex},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.$store.state
                                                            .servicios[1]
                                                            .ICON,"alt":"About Images"}})])}),0)],1)]),_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12"}},[_c('div',{staticClass:"details mt_md--30 mt_sm--30"},[_c('div',{staticClass:"section-title"},[_c('span',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$store.state .servicios[1].SUBTITULO))]),_c('h2',{staticClass:"heading-title"},[_vm._v(" "+_vm._s(_vm.$store.state .servicios[1].TITULO)+" ")])]),_c('globaleditor',{attrs:{"store":"update_Servicio","state":"servicios","index":"1","variable":"HTML"}},[_c('div',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"Titulo","outlined":""},model:{value:(
                                                        _vm.$store.state
                                                            .servicios[1]
                                                            .TITULO
                                                    ),callback:function ($$v) {_vm.$set(_vm.$store.state
                                                            .servicios[1]
                                                            , "TITULO", $$v)},expression:"\n                                                        $store.state\n                                                            .servicios[1]\n                                                            .TITULO\n                                                    "}}),_c('v-text-field',{attrs:{"label":"Sub Titulo","outlined":""},model:{value:(
                                                        _vm.$store.state
                                                            .servicios[1]
                                                            .SUBTITULO
                                                    ),callback:function ($$v) {_vm.$set(_vm.$store.state
                                                            .servicios[1]
                                                            , "SUBTITULO", $$v)},expression:"\n                                                        $store.state\n                                                            .servicios[1]\n                                                            .SUBTITULO\n                                                    "}})],1)]),_c('formatter',{attrs:{"HTMLdata":_vm.$store.state.servicios[1]
                                                    .HTML}})],1)])],1)],1)])])],1)],1)],1):_vm._e(),(_vm.$store.state.servicios.length > 0)?_c('div',{staticClass:"rn-service-details ptb--120 bg_color--5"},[_c('v-container',[_c('v-row',{staticClass:"sercice-details-content align-center row--35"},[_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12","order":"2","order-md":"1"}},[_c('div',{staticClass:"details mt_md--30 mt_sm--30"},[_c('div',{staticClass:"section-title"},[_c('span',{staticClass:"subtitle"},[_vm._v(_vm._s(_vm.$store.state.servicios[2].SUBTITULO))]),_c('h2',{staticClass:"heading-title"},[_vm._v(" "+_vm._s(_vm.$store.state.servicios[2].TITULO)+" ")])]),_c('globaleditor',{attrs:{"store":"update_Servicio","state":"servicios","index":"2","variable":"HTML"}},[_c('div',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"Titulo","outlined":""},model:{value:(
                                        _vm.$store.state.servicios[2].TITULO
                                    ),callback:function ($$v) {_vm.$set(_vm.$store.state.servicios[2], "TITULO", $$v)},expression:"\n                                        $store.state.servicios[2].TITULO\n                                    "}}),_c('v-text-field',{attrs:{"label":"Sub Titulo","outlined":""},model:{value:(
                                        _vm.$store.state.servicios[2].SUBTITULO
                                    ),callback:function ($$v) {_vm.$set(_vm.$store.state.servicios[2], "SUBTITULO", $$v)},expression:"\n                                        $store.state.servicios[2].SUBTITULO\n                                    "}})],1)]),_c('formatter',{attrs:{"HTMLdata":_vm.$store.state.servicios[2].HTML}})],1)]),_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12","order":"1","order-md":"2"}},[_c('div',{staticClass:"thumb position-relative"},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.$store.state.servicios[2].ICON,"alt":"Service Images"}})])])],1)],1)],1):_vm._e(),(_vm.$store.state.servicios.length > 0)?_c('div',{staticClass:"rn-service-details ptb--120 bg_color--1"},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"lg":"12"}},[_c('div',{staticClass:"service-details-inner"},[_c('div',{staticClass:"inner"},[_c('v-row',{staticClass:"sercice-details-content align-center row--35"},[_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12"}},[_c('div',{staticClass:"thumb position-relative"},[_c('CoolLightBox',{attrs:{"items":_vm.items,"index":_vm.index},on:{"close":function($event){_vm.index = null}}}),_c('div',{staticClass:"position-relative"},_vm._l((_vm.items),function(image,imageIndex){return _c('div',{key:imageIndex},[_c('img',{staticClass:"w-100",attrs:{"src":_vm.$store.state
                                                            .servicios[3]
                                                            .ICON,"alt":"About Images"}})])}),0)],1)]),_c('v-col',{attrs:{"lg":"6","md":"6","cols":"12"}},[_c('div',{staticClass:"details mt_md--30 mt_sm--30"},[_c('div',{staticClass:"section-title"},[_c('span',{staticClass:"subtitle"},[_vm._v(" "+_vm._s(_vm.$store.state .servicios[3].SUBTITULO))]),_c('h2',{staticClass:"heading-title"},[_vm._v(" "+_vm._s(_vm.$store.state .servicios[3].TITULO)+" ")])]),_c('globaleditor',{attrs:{"store":"update_Servicio","state":"servicios","index":"3","variable":"HTML"}},[_c('div',{staticClass:"mt-5"},[_c('v-text-field',{attrs:{"label":"Titulo","outlined":""},model:{value:(
                                                        _vm.$store.state
                                                            .servicios[3]
                                                            .TITULO
                                                    ),callback:function ($$v) {_vm.$set(_vm.$store.state
                                                            .servicios[3]
                                                            , "TITULO", $$v)},expression:"\n                                                        $store.state\n                                                            .servicios[3]\n                                                            .TITULO\n                                                    "}}),_c('v-text-field',{attrs:{"label":"Sub Titulo","outlined":""},model:{value:(
                                                        _vm.$store.state
                                                            .servicios[3]
                                                            .SUBTITULO
                                                    ),callback:function ($$v) {_vm.$set(_vm.$store.state
                                                            .servicios[3]
                                                            , "SUBTITULO", $$v)},expression:"\n                                                        $store.state\n                                                            .servicios[3]\n                                                            .SUBTITULO\n                                                    "}})],1)]),_c('formatter',{attrs:{"HTMLdata":_vm.$store.state.servicios[3]
                                                    .HTML}})],1)])],1)],1)])])],1)],1)],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }